import Vue from 'vue';
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.less';
// import './assets/less/style.less';
import Vant from 'vant';
import 'vant/lib/index.css';
import vConsole from 'vconsole';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './public/http';
import * as url from './public/url';
import * as constant from './public/constant';

// eslint-disable-next-line new-cap,no-unused-vars
const vconsole = new vConsole();

Vue.prototype.$url = url;
Vue.prototype.$http = http;
Vue.prototype.$constant = constant;
Vue.config.productionTip = false;
Vue.use(Vant);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
