/*
 * @Description: file content
 * @Author: Wang.Xichen
 * @Date: 2023-11-06 17:35:17
 * @LastEditors: Wang.Xichen
 * @LastEditTime: 2023-11-06 19:00:51
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "project" */ '../views/Login.vue')
  },
  { path: '*', redirect: '/login' }
];

const router = new VueRouter({
  routes,
});

export default router;
