const wechat = {};
const OPEN_ID_KEY = 'OPEN_ID_KEY';
const APP_KEY = 'WE_CHAT_APP_KEY';
const REDIRECT_URL = 'WE_CHAT_REDIRECT_URL';

wechat.isWeChat = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('micromessenger') >= 0) {
        return true;
    }

    return false;
};

wechat.setOpenId = (data) => localStorage.setItem(OPEN_ID_KEY, data);

wechat.getOpenId = () => localStorage.getItem(OPEN_ID_KEY);

wechat.setAppKey = (data) => localStorage.setItem(APP_KEY, data);

wechat.getAppKey = () => localStorage.getItem(APP_KEY);

wechat.setRedirectUrl = (data) => localStorage.setItem(REDIRECT_URL, data);

wechat.getRedirectUrl = () => localStorage.getItem(REDIRECT_URL);

export default wechat;
